import React, { FC, useMemo, useState } from 'react';

import { toast } from 'react-toastify';
import {
  useAsync, useLocale, useLogOperatorAction, hasPosition, api,
} from 'lib';
import { useSelector } from 'react-redux';
import { connectedCustomerSelector } from 'features/identity/exports';
import { mdiEmail } from '@mdi/js';
import { CheckboxField } from 'components';
import type { ContactOptionImplProps } from '..';
import { BuilderForm, initialFormState, BaseFormState } from '../builderForm';
import { useEmailBuilder } from './useEmailBuilder';

export * from './option';

export type FormState = BaseFormState & {
  personalInformation: boolean;
  alarmPosition: boolean;
};

export const EmailModal: FC<ContactOptionImplProps> = (props) => {

  const {
    alarm,
    responder,
    onDismiss,
  } = props;

  const { t } = useLocale();
  const [formValues, setFormValues] = useState<FormState>({
    ...initialFormState,
    includeContactCode: !!responder.info.contactCode,
    personalInformation: false,
    alarmPosition: hasPosition(alarm),
  });

  const customer = useSelector(connectedCustomerSelector);
  const emailOutput = useEmailBuilder(formValues, alarm, responder);

  const logAction = useLogOperatorAction({
    name: responder.info.name,
    sms: responder.actions.sms,
  });

  /**
   * Handles sending a mail
   */
  const sendEmail = useAsync(async (): Promise<unknown> => {

    // Dismiss the form when sending the mail
    onDismiss();

    logAction('ACTION_EMAIL');

    // Send a mail to every address in the email address in the contact
    const sendMail = api.post('contact/email', {
      html: emailOutput.getHtmlString(),
      subject: t('Alarm_Detail_Contact.Email.Subject'),
      to: [responder.actions.email],
    });

    // Wrap the mail request in a toast
    return toast.promise(sendMail, {
      pending: t('Alarm_Detail_Contact.Email.Toast.Sending'),
      success: t('Alarm_Detail_Contact.Email.Toast.Sent'),
      error: t('Alarm_Detail_Contact.Email.Toast.Error'),
    });

  }, [customer, emailOutput, t]);

  const [title, subtitle] = useMemo(() => {

    return [
      t('Alarm_Detail_Contact.Email.Title'),
      responder.actions.email.length > 1
        ? t('Alarm_Detail_Contact.Email.Subtitle.other')
        : t('Alarm_Detail_Contact.Email.Subtitle.one'),
    ];

  }, [t, responder]);

  return (
    <BuilderForm
      {...props}
      title={title}
      titleIcon={mdiEmail}
      subtitle={subtitle}
      send={sendEmail}
      dataFields={(
        <>
          <CheckboxField
            name={'personalInformation'}
            label={t('Alarm_Detail_Contact.Builder_Form.Add_Data.Fields.Personal_Information')}
            size={'small'}
          />
          {hasPosition(alarm) && (
            <CheckboxField
              name={'alarmPosition'}
              label={t('Alarm_Detail_Contact.Builder_Form.Add_Data.Fields.Alarm_Position')}
              size={'small'}
            />
          )}
        </>
      )}
      formValues={formValues}
      onSubmit={setFormValues}
      outputTitle={t('Alarm_Detail_Contact.Email.Output.Title')}
    >
      <emailOutput.component/>
    </BuilderForm>
  );

};

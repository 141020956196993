import React, { FC, Fragment } from 'react';

import Markdown, { Components } from 'react-markdown';

type BetterMarkdownProps = {
  children: string;
  components?: Components;
};

export const BetterMarkdown: FC<BetterMarkdownProps> = ({ children, components }) => {

  return (
    <Markdown components={{
      p: Fragment,
      ...(components ?? {}),
    }}>
      {children}
    </Markdown>
  );

};

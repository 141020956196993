import { mdiClipboardTextOutline, mdiPhone } from '@mdi/js';
import { Icon } from '@mdi/react';
import React, {
  ComponentPropsWithoutRef,
  ComponentType,
  createContext, FC, MouseEventHandler, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { Text } from 'components/atoms';
import { t } from 'config/i18n';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';
import { PopulatedAlarmProfessionalResponder } from 'types';
import { getPhoneNumber, useLocale, useLogOperatorAction } from 'lib';
import { appLogger } from 'lib/logger';
import styles from '../style.module.scss';

export const getResponderPhoneNumber = (responder: PopulatedAlarmProfessionalResponder, iso: string) => {

  const phoneNumber = getPhoneNumber(responder.meta.overridePhoneNumber, iso)
    ?? getPhoneNumber(responder.staticResponder.contact.phone, iso);

  // Fallback for invalid phone numbers
  if (!phoneNumber) {

    appLogger.error('Invalid phone number', { responder });

    const fallback = responder.meta.overridePhoneNumber ?? responder.staticResponder.contact.phone;

    return {
      phoneNumber: fallback,
      formattedPhoneNumber: fallback,
    };

  }

  return {
    phoneNumber: phoneNumber.uri,
    formattedPhoneNumber: phoneNumber.international,
  };

};

const PhoneNumberRevealedContext = createContext({
  revealed: false,
  reveal: () => {},
});

export type PhoneNumberRevealedContextProviderProps = PropsWithChildren<{
  responder: PopulatedAlarmProfessionalResponder;
}>;

export const PhoneNumberRevealedContextProvider: FC<PhoneNumberRevealedContextProviderProps> = ({
  responder,
  children,
}) => {

  const { iso } = useLocale();
  const [revealed, setRevealed] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const logAction = useLogOperatorAction({
    phone: getResponderPhoneNumber(responder, iso).formattedPhoneNumber,
    name: responder.staticResponder.name,
    targetAssetId: responder.asset._id,
  });

  const reveal = useCallback(() => {

    if (revealed) return;

    setRevealed(true);

    logAction('ACTION_CALL');

    timeoutRef.current = setTimeout(() => setRevealed(false), 30000);

  }, [revealed, logAction]);

  useEffect(() => {

    return () => {

      if (!timeoutRef.current) return;

      clearTimeout(timeoutRef.current);

    };

  }, []);

  return (
    <PhoneNumberRevealedContext.Provider value={{ revealed, reveal }}>
      {children}
    </PhoneNumberRevealedContext.Provider>
  );

};

export const PhoneNumberAnchor: ComponentType<ComponentPropsWithoutRef<'a'> & ReactMarkdownProps> = (props) => {

  const { revealed, reveal } = useContext(PhoneNumberRevealedContext);

  const onClick: MouseEventHandler = (e) => {

    if (!revealed) {

      reveal();

      e.preventDefault();

    }

  };

  const onClickClipboardButton = () => {

    const phoneNumber = props.href.split(':')[1];

    navigator.clipboard?.writeText(phoneNumber);

  };

  return (
    <div className={styles.phoneNumberContainer}>
      {revealed && (
        <div className={styles.telCopyToClipboard} onClick={onClickClipboardButton}>
          <Icon path={mdiClipboardTextOutline}/>
        </div>
      )}
      <a href={revealed ? props.href : ''} onClick={onClick}>
        <Icon path={mdiPhone}/>
        <Text theme={'dark'}>
          {revealed ? props.children : t('Alarm_Detail_Response.APR.DisplayPhoneNumber')}
        </Text>
      </a>
    </div>
  );

};

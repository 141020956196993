import React, {
  FC, PropsWithChildren, useMemo, memo, useContext,
} from 'react';
import cx from 'clsx';
import { Icon } from '@mdi/react';
import { mdiCheck, mdiPhone } from '@mdi/js';
import {
  childController, useCustomerContext, useLocale,
} from 'lib';
import { SpinnerCircular } from 'spinners-react';
import colors from 'style/colors.module.scss';
import { Text } from 'components/atoms';
import { useDispatch, useSelector } from 'react-redux';
import {
  startProfessionalResponse,
  professionalResponderSelector,
  startResponseStatusSelector, inProgressSelector, cancelResponseStatusSelector, cancelProfessionalResponse,
} from 'features/details/response/professional';
import { AlarmProfessionalResponderPhoto, AlarmProfessionalResponderStatus as Status } from '@x-guard/xgac-types/xgac';
import { BetterMarkdown, BetterMoment, Image } from 'components/molecules';
import moment from 'moment';
import styles from './style.module.scss';
import { useAlarmId } from '../../useAlarmId';
import { alarmContext } from '../../alarmContext';
import { getResponderPhoneNumber, PhoneNumberAnchor, PhoneNumberRevealedContextProvider } from './phoneNumberAnchor';
import { ackedSelector } from '../../../../../../features/details/ack/exports';

type ImagePreviewsProps = {
  photos?: AlarmProfessionalResponderPhoto[];
};

type DetailsProps = {
  step: number;
  photos?: AlarmProfessionalResponderPhoto[];
};

type InfoButtonKind = 'start' | 'cancel' | 'restart';

type InfoProps = PropsWithChildren<{
  kind: InfoButtonKind;
}>;

const ImagePreviews: FC<ImagePreviewsProps> = ({ photos }) => {

  const { t } = useLocale();

  if (!photos?.length) return null;

  const sortedPhotos = photos.sort((a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf());

  return (
    <div className={styles.imagePreviews}>
      <div className={styles.title}>
        {t('Alarm_Detail_Response.APR.ImagePreviews.Title')}
      </div>
      <div className={styles.grid}>
        {sortedPhotos.map((photo, index) => (
          <div key={index} className={styles.imagePreview}>
            <Image
              className={styles.image}
              src={photo.url}
              alt={photo.timestamp}
            />
            <BetterMoment
              className={styles.time}
              format={'HH:mm:ss'}
            >
              {moment(photo.timestamp)}
            </BetterMoment>
          </div>
        ))}
      </div>
    </div>
  );

};

const Details: FC<DetailsProps> = ({ step, children, photos }) => {

  const acked = useSelector(ackedSelector);
  const { t } = useLocale();

  const stateCell = (index: 0 | 1 | 2) => (
    <div className={styles.stateCellContainer}>
      <div className={cx(
        styles.stateCell,
        step > index && styles.loaded,
        step === index && styles.loading,
        step < index && styles.idle,
      )}>
        {step > index && <Icon path={mdiCheck}/>}
        {(step === index && !acked) && <SpinnerCircular
          thickness={150}
          color={colors.textBlack}
          secondaryColor={colors.textLight}
        />}
        <span>{t(`Alarm_Detail_Response.APR.Step.${index}.${step > index ? 'Done' : 'InProgress'}`)}</span>
      </div>
      {step === index && <div className={styles.loadingTail}/>}
    </div>
  );

  return (
    <div className={styles.details}>
      <div className={styles.state}>
        {stateCell(0)}
        <div className={styles.spacer}/>
        {stateCell(1)}
        <div className={styles.spacer}/>
        {stateCell(2)}
      </div>
      <div className={styles.content}>
        {children}
      </div>
      <ImagePreviews photos={photos}/>
    </div>
  );

};

const Info: FC<InfoProps> = ({ kind, children }) => {

  const { t } = useLocale();

  const responder = useSelector(professionalResponderSelector);
  const startStatus = useSelector(startResponseStatusSelector);
  const cancelStatus = useSelector(cancelResponseStatusSelector);
  const acked = useSelector(inProgressSelector);
  const { onClickAPRAction } = useContext(alarmContext);

  const dispatch = useDispatch();
  const alarmId = useAlarmId();
  const { signal } = useCustomerContext();

  const press = useMemo(() => {

    if (acked) return null;

    if (kind === 'cancel' && responder && !cancelStatus.pending) {

      return () => onClickAPRAction({
        type: 'cancel',
        action: () => {

          dispatch(cancelProfessionalResponse({
            controller: childController(signal),
            alarmId,
            aprId: responder._id,
          }));

        },
      });

    }

    if ((kind === 'start' || kind === 'restart') && !startStatus.pending) {

      return () => onClickAPRAction({
        type: kind,
        action: () => {

          dispatch(startProfessionalResponse({
            controller: childController(signal),
            alarmId,
          }));

        },
      });

    }

    return null;

  }, [kind, responder, startStatus, cancelStatus, alarmId, dispatch, signal, onClickAPRAction, acked]);

  return (
    <div className={styles.info}>
      <div className={styles.infoArea}>
        {children}
      </div>
      <div className={styles.chipButtonArea}>
        <div
          className={cx(styles.chipButton, styles[kind], press && styles.clickable)}
          onClick={press}
        >
          <Icon path={mdiPhone}/>
          <Text variant={'span'} theme={'dark'}>
            {t(`Alarm_Detail_Response.APR.${kind}`)}
          </Text>
        </div>
        {(kind === 'cancel' && !acked) && (
          <SpinnerCircular
            thickness={150}
            color={colors.textBlack}
            secondaryColor={colors.textLight}
            style={{ width: 24 }}
          />
        )}
      </div>
    </div>
  );

};

const Sub = () => {

  const { t } = useLocale();

  return (
    <div className={styles.sub}>
      <a
        href={'https://support.x-guard.nl/nl/help/oproepen-van-surveillanten-via-sequrix'}
        className={styles.anchor}
        target={'_blank'}
        rel="noreferrer"
      >
        {t('Alarm_Detail_Response.APR.Explanation')}
      </a>
    </div>
  );

};

export const ProfessionalResponder = memo(() => {

  const { t, iso } = useLocale();
  const responder = useSelector(professionalResponderSelector);
  const inProgress = useSelector(inProgressSelector);

  if (!responder || responder.status === Status.Canceled) {

    return <>
      <Info kind={inProgress ? 'cancel' : 'start'}>
        {inProgress ? <>
          <span className={styles.bold}>{t('Alarm_Detail_Response.APR.Info.InProgress.Main')}</span>
        </> : <>
          <span>{t('Alarm_Detail_Response.APR.Info.Ready.Main')}</span>
          <span className={styles.bold}>{t('Alarm_Detail_Response.APR.Info.Ready.Sub')}</span>
        </>}
      </Info>
      <Sub/>
    </>;

  }

  if (responder.status === Status.Rejected) {

    return <>
      <Info kind={'cancel'}>
        <span>{t('Alarm_Detail_Response.APR.Info.Rejected', { name: responder.staticResponder.name })}</span>
      </Info>
      <div className={styles.sub}>
        <span className={styles.bold}>{t('Alarm_Detail_Response.APR.FindingReplacement')}</span>
      </div>
      <Sub/>
    </>;

  }

  const phone = getResponderPhoneNumber(responder, iso);
  const infoStatus = responder.status === Status.Invited ? 'Finding' : 'Found';
  const comment = responder.meta.comment;

  return <>
    <Info kind={responder.status === Status.Finished ? 'restart' : 'cancel'}>
      <span>{t(`Alarm_Detail_Response.APR.Info.${infoStatus}`)}</span>
      <span className={styles.bold}>{responder.staticResponder.name}</span>
    </Info>
    <PhoneNumberRevealedContextProvider responder={responder}>
      {responder.status === Status.Invited && (
        <Details step={0}>
          <BetterMarkdown>
            {t('Alarm_Detail_Response.APR.Invited')}
          </BetterMarkdown>
        </Details>
      )}
      {responder.status === Status.Accepted && (
        <Details step={1}>
          <BetterMarkdown components={{ a: PhoneNumberAnchor }}>
            {t('Alarm_Detail_Response.APR.Accepted', phone)}
          </BetterMarkdown>
        </Details>
      )}
      {responder.status === Status.OnLocation && (
        <Details step={2}>
          <BetterMarkdown components={{ a: PhoneNumberAnchor }}>
            {t('Alarm_Detail_Response.APR.OnLocation', phone)}
          </BetterMarkdown>
        </Details>
      )}
      {responder.status === Status.Finished && (
        <Details step={3} photos={responder.meta.photos}>
          <BetterMarkdown>{t('Alarm_Detail_Response.APR.Finished')}</BetterMarkdown>
          {comment && (
            <div className={styles.comment}>
              <BetterMarkdown>{t('Alarm_Detail_Response.APR.Comment', { comment })}</BetterMarkdown>
            </div>
          )}
        </Details>
      )}
    </PhoneNumberRevealedContextProvider>
    <Sub/>
  </>;

});
